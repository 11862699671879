<template>
    <div class="profileLayout">
        <!-- <profile-side-bar /> -->

        <profile-area-wrapper
            class="mb-5 px-4 px-xl-5"
            :header="$t('profile.editProfile.header')"
            :subHeader="$t('profile.editProfile.subHeader')"
        >
            <transition name="fade">
                <div
                    class="alert alert-success alert-dismissible user-alert"
                    role="alert"
                    v-if="alert"
                >
                    User updated successfully!
                    <a
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        @click="alert = false"
                    ></a>
                </div>
            </transition>
            <div class="edit-profile-form p-3 p-sm-5" v-if="user">
                <div class="mb-3 text-center">
                    <img
                        v-if="user.avatar"
                        class="img-fluid rounded-circle"
                        width="75"
                        :src="user.avatar"
                        alt="user"
                    />
                    <img
                        v-else
                        class="img-fluid rounded-circle"
                        width="100"
                        src="@/assets/img/empty_user.png"
                        alt="empty_user"
                    />
                </div>
                <div class="mb-3">
                    <label for="avatar" class="form-label"> Image </label>
                    <input
                        @change="addAvatar"
                        type="file"
                        class="form-control border-0"
                        id="avatar"
                    />
                </div>

                <div class="mb-3">
                    <label for="Name" class="form-label"> Name </label>
                    <input
                        v-model="user.name"
                        type="text"
                        class="form-control border-0"
                        id="Name"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="submitStatus == 'ERROR' && !$v.user.name.required"
                    >
                        User Name is required.
                    </div>
                </div>

                <div class="mb-3">
                    <label for="Email" class="form-label"> Email </label>
                    <input
                        v-model="user.email"
                        type="email"
                        class="form-control border-0"
                        id="Email"
                        aria-describedby="emailHelp"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="
                            submitStatus == 'ERROR' && !$v.user.email.required
                        "
                    >
                        User email is required.
                    </div>
                    <div
                        class="error invalid-feedback d-block"
                        v-if="submitStatus == 'ERROR' && !$v.user.email.email"
                    >
                        User email must be email.
                    </div>
                </div>

                <div class="mb-3">
                    <label for="phone" class="form-label"> Phone </label>
                    <input
                        v-model="user.phone"
                        type="text"
                        class="form-control border-0"
                        id="phone"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="
                            submitStatus == 'ERROR' && !$v.user.phone.required
                        "
                    >
                        Phone is required.
                    </div>
                    <!-- <div class="container-fluid p-0">
                        <label for="phone" class="form-label"> Phone </label>

                        <div class="phoneGrid">
                            <div class="country-box position-relative">
                                <div
                                    class="show-country p-1"
                                    @click="handleCountryBox"
                                >
                                    <template v-if="selectedCountry">
                                        <img
                                            class="me-2"
                                            :src="countryFlag"
                                            :alt="selectedCountry.name"
                                        />
                                        {{ selectedCountry.dialCode }}
                                    </template>
                                </div>
                                <div
                                    class="country-list position-absolute"
                                    v-if="showCountryBox"
                                >
                                    <div class="list-group">
                                        <a
                                            href="#"
                                            v-for="(country,
                                            index) in countries"
                                            :key="index"
                                            class="
                                                list-group-item
                                                list-group-item-action
                                                border-0
                                                py-3
                                                px-4
                                            "
                                            @click="
                                                chooseCountry(
                                                    country.code,
                                                    country.name,
                                                    country.dial_code,
                                                )
                                            "
                                        >
                                            {{ country.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <input
                                v-model="user.phone"
                                type="text"
                                class="form-control border-0"
                                id="phone"
                            />
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.user.phone.required
                            "
                        >
                            Phone is required.
                        </div>
                    </div> -->
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label"
                        >Full Address(for delivery)</label
                    >
                    <textarea
                        v-model="user.shipping_address"
                        class="form-control border-0"
                        id="address"
                        rows="3"
                    ></textarea>
                </div>
                <div
                    class="error invalid-feedback d-block mb-3"
                    v-if="submitStatus == 'ERROR' && authError"
                >
                    {{ authError }}
                </div>
                <div
                    class="error invalid-feedback d-block mb-3"
                    v-if="submitStatus == 'ERROR' && authPhoneError"
                >
                    {{ authPhoneError }}
                </div>
                <div class="mb-3 edit-btn">
                    <edit-button
                        v-if="!updateLoading"
                        @clicked="updateProfile"
                        :name="$t('profile.editProfile.updateButton')"
                    />
                    <button class="btn" v-else type="button" disabled>
                        <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </button>
                </div>
            </div>
        </profile-area-wrapper>
    </div>
</template>

<script>
// import ProfileSideBar from '../reusable/ProfileSideBar';
import ProfileAreaWrapper from '../reusable/ProfileAreaWrapper';
import Button from '../reusable/Button';
import { mapGetters } from 'vuex';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

// apollo mutaions
import UserUpdate from '@/graphql/mutations/auth/UserUpdate.gql';

export default {
    mixins: [validationMixin],

    components: {
        // 'profile-side-bar': ProfileSideBar,
        'profile-area-wrapper': ProfileAreaWrapper,
        'edit-button': Button,
    },

    data() {
        return {
            submitStatus: null,
            selectedCountry: null,
            countries: null,
            showCountryBox: false,
            alert: false,
            authPhoneError: '',
            authError: '',
            avatarImage: null,
            updateLoading: false,
        };
    },

    validations: {
        user: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            phone: {
                required,
            },
        },
    },

    mounted() {
        this.selectedCountry = {
            code: 'MM',
            name: 'Myanmar',
            dialCode: '+95',
        };
        this.getCountries();
    },

    methods: {
        getCountries() {
            this.axios
                .get(
                    'https://gist.githubusercontent.com/Goles/3196253/raw/9ca4e7e62ea5ad935bb3580dc0a07d9df033b451/CountryCodes.json',
                )
                .then(response => {
                    this.countries = response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        handleCountryBox() {
            this.showCountryBox = !this.showCountryBox;
        },

        chooseCountry(code, name, dialCode) {
            this.selectedCountry = {
                code: code,
                name: name,
                dialCode: dialCode,
            };
        },

        addAvatar(e) {
            this.avatarImage = e.target.files[0];
        },

        updateProfile() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.updateLoading = true;
                if (this.avatarImage) {
                    this.$apollo
                        .mutate({
                            mutation: UserUpdate,

                            variables: {
                                id: this.user.id,
                                name: this.user.name,
                                email: this.user.email,
                                phone: this.user.phone,
                                shipping_address: this.user.shipping_address,
                                avatar: this.avatarImage,
                            },
                        })
                        .then(response => {
                            console.log(response.data);
                            this.submitStatus = 'OK';
                            this.$store.commit(
                                'auth/UPDATE_USER',
                                response.data.updateUser,
                            );
                            this.alert = true;
                            this.updateLoading = false;
                        })
                        .catch(errors => {
                            console.log(errors);
                            this.submitStatus = 'ERROR';
                            this.updateLoading = false;

                            if (
                                errors.graphQLErrors[0].extensions.validation[
                                    'email'
                                ]
                            ) {
                                this.authError =
                                    'The email has already been taken.';
                            } else if (
                                errors.graphQLErrors[0].extensions.validation[
                                    'phone'
                                ]
                            ) {
                                this.authPhoneError =
                                    'Phone Number has already been taken.';
                            } else {
                                this.authError =
                                    errors.graphQLErrors[0].extensions.reason;
                            }
                        });
                } else {
                    this.$apollo
                        .mutate({
                            mutation: UserUpdate,

                            variables: {
                                id: this.user.id,
                                name: this.user.name,
                                email: this.user.email,
                                phone: this.user.phone,
                                shipping_address: this.user.shipping_address,
                            },
                        })
                        .then(response => {
                            console.log(response.data);
                            this.submitStatus = 'OK';
                            this.$store.commit(
                                'auth/UPDATE_USER',
                                response.data.updateUser,
                            );
                            this.alert = true;
                            this.updateLoading = false;
                        })
                        .catch(errors => {
                            console.log(errors);
                            this.submitStatus = 'ERROR';
                            this.updateLoading = false;

                            if (
                                errors.graphQLErrors[0].extensions.validation[
                                    'email'
                                ]
                            ) {
                                this.authError =
                                    'The email has already been taken.';
                            } else if (
                                errors.graphQLErrors[0].extensions.validation[
                                    'phone'
                                ]
                            ) {
                                this.authPhoneError =
                                    'Phone Number has already been taken.';
                            } else {
                                this.authError =
                                    errors.graphQLErrors[0].extensions.reason;
                            }
                        });
                }
            }
        },
    },

    computed: {
        countryFlag() {
            return (
                'https://www.countryflags.io/' +
                this.selectedCountry.code +
                '/flat/64.png'
            );
        },

        ...mapGetters({
            user: 'auth/getUser',
        }),
    },
};
</script>

<style lang="scss" scoped>
// @import '@/sass/components/profileLayout.scss';
@import '@/sass/components/profileEdit.scss';
</style>
