<template>
    <div class="container pt-5">
        <div class="profile-heading mb-4">
            <h2 class="title--secondary">{{ header }}</h2>
            <p class="title--tertiary">{{ subHeader }}</p>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
        },
        subHeader: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.profile-heading {
    h1 {
        font-size: 42px;
    }
}
</style>
