<template>
    <profile-edit />
</template>

<script>
import ProfileEdit from '../../components/profile/Edit';

export default {
    components: {
        'profile-edit': ProfileEdit,
    },
};
</script>
